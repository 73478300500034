import {useContext} from 'react';
import { AuthContext } from '../context/auth-context';
import { PRACTITIONER_STATUS } from '../data/constants';

export function getMessagingForProtectedContent(returnRichText = true, showFirstSentenceOnly = false) {
    const { state: authState, actions: authActions } = useContext(AuthContext);
    const { auth, practitioner } = authState;
    const practitionerStatus = practitioner.status || '';

	const genericMessagePart = `Your account status is '${practitionerStatus}'`;

	let message;
	switch (practitionerStatus.toUpperCase()) {
		case PRACTITIONER_STATUS.INCOMPLETE:
			message = `Complete your application to get access to our products [here](/account/register). This page is only available for approved users.`;
			break;
		case PRACTITIONER_STATUS.PENDING:
			message = `
				You will be able to view this content once your application is approved.
				Your new account is being processed.
				Please allow us 1-2 business days to review your application.
				[Contact customer support](/contact-us/) if you have any questions.
			`.trim();
			break;
		case PRACTITIONER_STATUS.UNAPPROVED:
			message = `You are unable to view this content. ${genericMessagePart}.`;
			break;
		case PRACTITIONER_STATUS.ACTIVE:
			message = `Your user status is '${PRACTITIONER_STATUS.ACTIVE}' and this content should be visible to you.`;
			break;
		default:
			message = `[Log In](/account/login) to view this content.`;
	}

	if (returnRichText === false) {
		message = message
			.replace(' [here](/account/register)', '')	//	Special handling of the INCOMPLETE message for plain/text where link label doesn't make sense in plain/text.
			.replace(/\s+/ig, ' ')						//	Strip whitespace
			.replace(/\[([^[]+)\]\([^()]+\)/gi, '$1');	//	Replace links with only their text portion
	}
	if (showFirstSentenceOnly === true) {
		message = `${message.split('.').slice(0, 1)}.`;
	}

	return message;
}
