/* eslint-disable prefer-template */

import {
  STOREFRONT_API_ACCESS_TOKEN as SHOPIFY_STOREFRONT_API_TOKEN,
  STOREFRONT_API_URL as SHOPIFY_STOREFRONT_API_URL,
} from '../config';
import { ExtendedError } from '../error';

const resolve = model => {
  if (!model || typeof model !== 'object') {
    return model;
  }

  const resolvedNode = Object.entries(model).reduce((acc, cur) => {
    const [key, value] = cur;
    acc[key] = value?.edges
      ? value.edges.map(({ node, ...rest }) => resolve({ ...node, ...rest }))
      : resolve(value);
    return acc;
  }, model);

  return resolvedNode;
};

const fetcher = async options => {
  const { variables, query, params } = options;
  const searchParams = new URLSearchParams(params);
  const url = SHOPIFY_STOREFRONT_API_URL + '?' + searchParams;

  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify({ query, variables }),
    headers: {
      'X-Shopify-Storefront-Access-Token': SHOPIFY_STOREFRONT_API_TOKEN,
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(url, fetchOptions);

  if (!response.ok) {
    throw new ExtendedError({
      response,
      code: 'shopify_http_error',
      message: 'Failed to fetch Shopify Storefront API',
      status: response.status,
      statusText: response.statusText,
    });
  }

  const { data, errors } = await response.json();

  if (errors?.length) {
    throw new ExtendedError({
      errors,
      code: 'shopify_gql_error',
      message: 'GraphQL responded with errors',
    });
  }

  return resolve(data);
};

const addressFragment = `
  fragment AddressFragment on MailingAddress {
    id
    address1
    address2
    city
    company
    country
    countryCode: countryCodeV2
    countryCodeV2
    firstName
    lastName
    phone
    province
    provinceCode
    zip
  }
`;

const moneyFragment = `
  fragment MoneyFragment on MoneyV2 {
    amount
    currencyCode
  }
`;

const attributeFragment = `
  fragment AttributeFragment on Attribute {
    key
    value
  }
`;

const appliedGiftCardFragment = `
  fragment AppliedGiftCardFragment on AppliedGiftCard {
    id
    lastCharacters
    amountUsed {
      ...MoneyFragment
    }
    amountUsedV2: amountUsed {
      ...MoneyFragment
    }
    balance {
      ...MoneyFragment
    }
    balanceV2: balance {
      ...MoneyFragment
    }
    presentmentAmountUsed {
      ...MoneyFragment
    }
    presentmentAmountUsedV2: presentmentAmountUsed {
      ...MoneyFragment
    }
  }
`;

const discountApplicationFragment = `
  fragment DiscountApplicationFragment on DiscountApplication {
    allocationMethod
    targetSelection
    targetType
    value
  }
`;

const variantFragment = `
  fragment VariantFragment on ProductVariant {
    availableForSale
    compareAtPrice {
      ...MoneyFragment
    }
    compareAtPriceV2: compareAtPrice {
      ...MoneyFragment
    }
    id
    image {
      altText
      height
      width
      id
      url
      src: url
    }
    selectedOptions {
      name
      value
    }
    sku
    title
    price {
      ...MoneyFragment
    }
    priceV2: price {
      ...MoneyFragment
    }
    unitPrice {
      ...MoneyFragment
    }
    unitPriceV2: unitPrice {
      ...MoneyFragment
    }
  }
`;

const orderLineItemFragment = `
  fragment OrderLineItemFragment on OrderLineItem {
    quantity
    title
    currentQuantity
    customAttributes {
      ...AttributeFragment
    }
    discountedTotalPrice {
      ...MoneyFragment
    }
    discountedTotalPriceV2: discountedTotalPrice {
      ...MoneyFragment
    }
    discountAllocations {
      allocatedAmount {
        ...MoneyFragment
      }
      allocatedAmountV2: allocatedAmount {
        ...MoneyFragment
      }
      discountApplication {
        ...DiscountApplicationFragment
      }
    }
    originalTotalPrice {
      ...MoneyFragment
    }
    originalTotalPriceV2: originalTotalPrice {
      ...MoneyFragment
    }
    variant {
      ...VariantFragment
      ...on ProductVariant {
        product {
          handle
          id
          title
          tags
          priceRange {
            minVariantPrice {
              ...MoneyFragment
            }
          }
          images(first: 250) {
            edges {
              node {
                altText
                originalSrc
              }
            }
          }
        }
      }
    }
  }
`;

const orderFragment = `
  fragment OrderFragment on Order {
    cancelReason
    canceledAt
    currencyCode
    customerLocale
    customerUrl
    edited
    email
    financialStatus
    fulfillmentStatus
    id
    name
    orderNumber
    phone
    processedAt
    discountApplications(first: 250) {
      edges {
        node {
          ...DiscountApplicationFragment
        }
      }
    }
    lineItems(first: 250) {
      edges {
        node {
          ...OrderLineItemFragment
        }
      }
    }
    shippingAddress {
      ...AddressFragment
    }
    shippingDiscountAllocations {
      allocatedAmount {
        ...MoneyFragment
      }
      discountApplication {
        ...DiscountApplicationFragment
      }
    }
    subtotalPrice {
      ...MoneyFragment
    }
    subtotalPriceV2: subtotalPrice {
      ...MoneyFragment
    }
    successfulFulfillments(first: 250) {
      trackingCompany
      trackingInfo {
        number
        url
      }
    }
    totalPrice {
      ...MoneyFragment
    }
    totalPriceV2: totalPrice {
      ...MoneyFragment
    }
    totalRefunded {
      ...MoneyFragment
    }
    totalRefundedV2: totalRefunded {
      ...MoneyFragment
    }
    totalShippingPrice {
      ...MoneyFragment
    }
    totalShippingPriceV2: totalShippingPrice {
      ...MoneyFragment
    }
    currentTotalTax {
      ...MoneyFragment
    }
    currentTotalTaxV2: currentTotalTax {
      ...MoneyFragment
    }
    totalTax {
      ...MoneyFragment
    }
    totalTaxV2: totalTax {
      ...MoneyFragment
    }
  }
`;

const customerOrdersFragment = `
  fragment CustomerOrdersFragment on Customer {
    orders(first: 250, reverse: true) {
      edges {
        node {
          ...OrderFragment
        }
      }
    }
  }

  ${orderFragment}
  ${attributeFragment}
  ${orderLineItemFragment}
  ${variantFragment}
  ${discountApplicationFragment}
  ${moneyFragment}
`;

const customerFragment = `
  fragment CustomerFragment on Customer {
    id
    firstName
    lastName   
    numberOfOrders  
    email
    phone
    defaultAddress {
      ...AddressFragment
    }
    addresses(first: 250) {
      edges {
        node {
          ...AddressFragment
        }
      }
    }
  }

  ${addressFragment}
`;

const cartLineFragment = `
  fragment CartLineFragment on CartLine {
    id
    quantity
    attributes {
      ...AttributeFragment
    }
    cost {
      amountPerQuantity {
        ...MoneyFragment
      }
      compareAtAmountPerQuantity {
        ...MoneyFragment
      }
      subtotalAmount {
        ...MoneyFragment
      }
      totalAmount {
        ...MoneyFragment
      }
      amountPerQuantityV2: amountPerQuantity {
        ...MoneyFragment
      }
      compareAtAmountPerQuantityV2: compareAtAmountPerQuantity {
        ...MoneyFragment
      }
      subtotalAmountV2: subtotalAmount {
        ...MoneyFragment
      }
      totalAmountV2: totalAmount {
        ...MoneyFragment
      }
    }
    customAttributes: attributes {
      ...AttributeFragment
    }
    discountAllocations {
      discountedAmount {
        ...MoneyFragment
      }
    }
    sellingPlanAllocation {
      checkoutChargeAmount {
        ...MoneyFragment
      }
      priceAdjustments {
        compareAtPrice {
          ...MoneyFragment
        }
        price {
          ...MoneyFragment
        }
        unitPrice {
          ...MoneyFragment
        }
        compareAtPriceV2: compareAtPrice {
          ...MoneyFragment
        }
        priceV2: price {
          ...MoneyFragment
        }
        unitPriceV2: unitPrice {
          ...MoneyFragment
        }
      }
      sellingPlan {
        description
        id
        name
        recurringDeliveries
        checkoutCharge {
          type
          value
        }
        priceAdjustments {
          adjustmentValue
          orderCount
        }
      }
    }
    variant: merchandise {
      ...VariantFragment
      ...on ProductVariant {
        product {
          handle
          id
          title
        }
      }
    }
  }
`;

const cartFragment = `
  fragment CartFragment on Cart {
    checkoutUrl
    createdAt
    id
    note
    totalQuantity
    updatedAt
    webUrl: checkoutUrl
    attributes {
      ...AttributeFragment
    }
    cost {
      checkoutChargeAmount {
        ...MoneyFragment
      }
      subtotalAmount {
        ...MoneyFragment
      }
      totalAmount {
        ...MoneyFragment
      }
      totalDutyAmount {
        ...MoneyFragment
      }
      totalTaxAmount {
        ...MoneyFragment
      }
      checkoutChargeAmountV2: checkoutChargeAmount {
        ...MoneyFragment
      }
      subtotalAmountV2: subtotalAmount {
        ...MoneyFragment
      }
      totalAmountV2: totalAmount {
        ...MoneyFragment
      }
      totalDutyAmountV2: totalDutyAmount {
        ...MoneyFragment
      }
      totalTaxAmountV2: totalTaxAmount {
        ...MoneyFragment
      }
    }
    discountAllocations {
      discountedAmount {
        ...MoneyFragment
      }
    }
    discountCodes {
      applicable
      code
    }
    lineItems: lines(first: 250) {
      edges {
        node {
          ...CartLineFragment
        }
      }
    }
  }

  ${attributeFragment}
  ${moneyFragment}
  ${variantFragment}
  ${cartLineFragment}
`;

const checkoutFragment = `
  fragment CheckoutFragment on Checkout {
    completedAt
    createdAt
    currencyCode
    email
    id
    note
    orderStatusUrl
    ready
    requiresShipping
    taxesIncluded
    taxExempt
    updatedAt
    webUrl
    appliedGiftCards {
      ...AppliedGiftCardFragment
    }
    customAttributes {
      ...AttributeFragment
    }
    discountApplications(first: 250) {
      edges {
        node {
          ...DiscountApplicationFragment
        }
      }
    }
    lineItems(first: 250) {
      edges {
        node {
          id
          quantity
          title
          customAttributes {
            ...AttributeFragment
          }
          discountAllocations {
            allocatedAmount {
              ...MoneyFragment
            }
            discountApplication {
              ...DiscountApplicationFragment
            }
          }
          variant {
            ...VariantFragment
            ...on ProductVariant {
              product {
                handle
                id
                title
              }
            }
          }
        }
      }
    }
    lineItemsSubtotalPrice {
      ...MoneyFragment
    }
    lineItemsSubtotalPriceV2: lineItemsSubtotalPrice {
      ...MoneyFragment
    }
    order {
      ...OrderFragment
    }
    paymentDue {
      ...MoneyFragment
    }
    paymentDueV2: paymentDue {
      ...MoneyFragment
    }
    shippingAddress {
      ...AddressFragment
    }
    subtotalPrice {
      ...MoneyFragment
    }
    subtotalPriceV2: subtotalPrice {
      ...MoneyFragment
    }
    totalPrice {
      ...MoneyFragment
    }
    totalPriceV2: totalPrice {
      ...MoneyFragment
    }
    totalTax {
      ...MoneyFragment
    }
    totalTaxV2: totalTax {
      ...MoneyFragment
    }
  }

  ${addressFragment}
  ${appliedGiftCardFragment}
  ${attributeFragment}
  ${discountApplicationFragment}
  ${moneyFragment}
  ${orderFragment}
  ${orderLineItemFragment}
  ${variantFragment}
`;

const customerAccessTokenFragment = `
  fragment CustomerAccessTokenFragment on CustomerAccessToken {
    accessToken
    expiresAt
  }
`;

const customerUserErrorFragment = `
  fragment CustomerUserErrorFragment on CustomerUserError {
    code
    field
    message
  }
`;

const checkoutUserErrorFragment = `
  fragment CheckoutUserErrorFragment on CheckoutUserError {
    code
    field
    message
  }
`;

const cartUserErrorFragment = `
  fragment CartUserErrorFragment on CartUserError {
    code
    field
    message
  }
`;

const userErrorFragment = `
  fragment UserErrorFragment on UserError {
    field
    message
  }
`;

/**
 * @param {string} multipassToken - Shopify multipass token
 * @returns Promise<object>
 */
export const createCustomerTokenWithMultipass = async multipassToken => {
  const query = `
    mutation customerAccessTokenCreateWithMultipass($multipassToken: String!) {
      customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
        customerAccessToken {
          ...CustomerAccessTokenFragment
        }
        customerUserErrors {
          ...CustomerUserErrorFragment
        }
      }
    }

    ${customerAccessTokenFragment}
    ${customerUserErrorFragment}
  `;

  const variables = { multipassToken };
  const data = await fetcher({ query, variables });

  const { customerAccessToken, customerUserErrors } =
    data.customerAccessTokenCreateWithMultipass || {};

  if (customerUserErrors?.length) {
    throw new ExtendedError({
      code: 'shopify_user_error',
      message: 'User errors occurred during GraphQL execution',
      errors: customerUserErrors,
    });
  }

  return customerAccessToken || {};
};

/**
 * @param {string} accessToken - Shopify customer access token
 * @returns Promise<object>
 */
export const renewCustomerToken = async accessToken => {
  const query = `
    mutation customerAccessTokenRenew($accessToken: String!) {
      customerAccessTokenRenew(customerAccessToken: $accessToken) {
        customerAccessToken {
          ...CustomerAccessTokenFragment
        }
        userErrors {
          ...UserErrorFragment
        }
      }
    }

    ${customerAccessTokenFragment}
    ${userErrorFragment}
  `;

  const variables = { accessToken };
  const data = await fetcher({ query, variables });
  const { customerAccessToken, userErrors } = data.customerAccessTokenRenew || {};

  if (userErrors?.length) {
    throw new ExtendedError({
      code: 'shopify_user_error',
      message: 'User errors occurred during GraphQL execution',
      errors: userErrors,
    });
  }

  return customerAccessToken || {};
};

/**
 * @param {object} customerAccessToken
 * @param {string} customerAccessToken.accessToken
 * @param {string} [customerAccessToken.expiresAt]
 * @param {boolean} [shouldForce]
 * @returns Promise<object>
 */
export const refreshToken = (customerAccessToken, shouldForce = false) => {
  const { accessToken, expiresAt = null } = customerAccessToken;

  // Shopify Customer access tokens expire after 12 days
  const sixDays = 1000 * 60 * 60 * 24 * 6;
  const sixDaysDt = new Date().getTime() + sixDays;
  const expiresAtDt = new Date(expiresAt).getTime();

  if (expiresAtDt > sixDaysDt && !shouldForce) {
    return Promise.resolve(customerAccessToken);
  }

  return renewCustomerToken(accessToken);
};

/**
 * @param {string} accessToken - Shopify customer access token
 * @param {object} customerInput - Shopify customer updates
 * @returns Promise<object>
 */
export const updateCustomer = async (accessToken, customerInput) => {
  const query = `
    mutation customerUpdate($customerInput: CustomerUpdateInput!, $accessToken: String!) {
      customerUpdate(customer: $customerInput, customerAccessToken: $accessToken) {
        customer {
          ...CustomerFragment
          ...CustomerOrdersFragment
        }
        customerAccessToken {
          ...CustomerAccessTokenFragment
        }
        customerUserErrors {
          ...CustomerUserErrorFragment
        }
      }
    }

    ${customerFragment}
    ${customerOrdersFragment}
    ${customerAccessTokenFragment}
    ${customerUserErrorFragment}
  `;

  const variables = { customerInput, accessToken };
  const data = await fetcher({ query, variables });
  const { customer, customerAccessToken, customerUserErrors } =
    data.customerUpdate || {};

  if (customerUserErrors?.length) {
    throw new ExtendedError({
      code: 'shopify_user_error',
      message: 'User errors occurred during GraphQL execution',
      errors: customerUserErrors,
    });
  }

  return { customer, customerAccessToken };
};

/**
 * @param {string} accessToken - Shopify customer access token
 * @returns Promise<object>
 */
export const getCustomer = async accessToken => {
  const query = `
    query customer($accessToken: String!) {
      customer(customerAccessToken: $accessToken) {
        ...CustomerFragment
        ...CustomerOrdersFragment
      }
    }

    ${customerFragment}
    ${customerOrdersFragment}
  `;

  const variables = { accessToken };
  const data = await fetcher({ query, variables });
  return data.customer || {};
};

/**
 * @param {string} accessToken - Shopify customer access token
 * @returns Promise<object[]>
 */
export const getCustomerOrders = async accessToken => {
  const query = `
    query customer($accessToken: String!) {
      customer(customerAccessToken: $accessToken) {
        ...CustomerOrdersFragment
      }
    }

    ${customerOrdersFragment}
    ${addressFragment}
  `;

  const variables = { accessToken };
  const data = await fetcher({ query, variables });
  const customer = data.customer || {};
  return customer.orders || [];
};

/**
 * @param {string} accessToken - Shopify customer access token
 * @param {object} address - Shopify address data
 * @returns Promise<object>
 */
export const createAddress = async (accessToken, address) => {
  const query = `
    mutation customerAddressCreate($address: MailingAddressInput!, $accessToken: String!) {
      customerAddressCreate(address: $address, customerAccessToken: $accessToken) {
        customerAddress {
          ...AddressFragment
        }
        customerUserErrors {
          ...CustomerUserErrorFragment
        }
      }
    }

    ${addressFragment}
    ${customerUserErrorFragment}
  `;

  const variables = { accessToken, address };
  const data = await fetcher({ query, variables });
  const { customerAddress, customerUserErrors } =
    data.customerAddressCreate || {};

  if (customerUserErrors?.length) {
    throw new ExtendedError({
      code: 'shopify_user_error',
      message: 'User errors occurred during GraphQL execution',
      errors: customerUserErrors,
    });
  }

  return customerAddress || {};
};

/**
 * @param {string} accessToken - Shopify customer access token
 * @param {string} id - Shopify address ID
 * @param {object} address - Shopify address data
 * @returns Promise<object>
 */
export const updateAddress = async (accessToken, id, address) => {
  const query = `
    mutation customerAddressUpdate($address: MailingAddressInput!, $accessToken: String!, $id: ID!) {
      customerAddressUpdate(address: $address, customerAccessToken: $accessToken, id: $id) {
        customerAddress {
          ...AddressFragment
        }
        customerUserErrors {
          ...CustomerUserErrorFragment
        }
      }
    }

    ${addressFragment}
    ${customerUserErrorFragment}
  `;

  const variables = { accessToken, address, id };
  const data = await fetcher({ query, variables });
  const { customerAddress, customerUserErrors } =
    data.customerAddressUpdate || {};

  if (customerUserErrors?.length) {
    throw new ExtendedError({
      code: 'shopify_user_error',
      message: 'User errors occurred during GraphQL execution',
      errors: customerUserErrors,
    });
  }

  return customerAddress || {};
};

/**
 * @param {string} accessToken - Shopify customer access token
 * @param {string} id - Shopify address ID
 * @returns Promise<string>
 */
export const deleteAddress = async (accessToken, id) => {
  const query = `
    mutation customerAddressDelete($accessToken: String!, $id: ID!) {
      customerAddressDelete(customerAccessToken: $accessToken, id: $id) {
        customerUserErrors {
          ...CustomerUserErrorFragment
        }
        deletedCustomerAddressId
      }
    }

    ${customerUserErrorFragment}
  `;

  const variables = { accessToken, id };
  const data = await fetcher({ query, variables });
  const { deletedCustomerAddressId, customerUserErrors } =
    data.customerAddressDelete || {};

  if (customerUserErrors?.length) {
    throw new ExtendedError({
      code: 'shopify_user_error',
      message: 'User errors occurred during GraphQL execution',
      errors: customerUserErrors,
    });
  }

  return deletedCustomerAddressId || '';
};

/**
 * @param {string} accessToken - Shopify customer access token
 * @param {string} id - Shopify address ID
 * @returns Promise<object>
 */
export const updateDefaultAddress = async (accessToken, id) => {
  const query = `
    mutation customerDefaultAddressUpdate($id: ID!, $accessToken: String!) {
      customerDefaultAddressUpdate(addressId: $id, customerAccessToken: $accessToken) {
        customer {
          ...CustomerFragment
          ...CustomerOrdersFragment
        }
        customerUserErrors {
          ...CustomerUserErrorFragment
        }
      }
    }

    ${customerFragment}
    ${customerOrdersFragment}
    ${customerUserErrorFragment}
  `;

  const variables = { accessToken, id };
  const data = await fetcher({ query, variables });
  const { customer, customerUserErrors } =
    data.customerDefaultAddressUpdate || {};

  if (customerUserErrors?.length) {
    throw new ExtendedError({
      code: 'shopify_user_error',
      message: 'User errors occurred during GraphQL execution',
      errors: customerUserErrors,
    });
  }

  return customer || {};
};

/**
 * @param {object} input - Shopify checkout create input
 * @param {object} [options] - Fetcher options
 * @param {object} [options.params] - Query params
 * @returns Promise<object>
 */
export const createCheckout = async (input = {}, options = {}) => {
  const query = `
    mutation ($input: CheckoutCreateInput!) {
      checkoutCreate(input: $input) {
        checkout {
          ...CheckoutFragment
        }
        checkoutUserErrors {
          ...CheckoutUserErrorFragment
        }
        userErrors {
          ...UserErrorFragment
        }
      }
    }

    ${checkoutFragment}
    ${checkoutUserErrorFragment}
    ${userErrorFragment}
  `;

  const variables = { input };
  const data = await fetcher({ query, variables, ...options });
  const { checkout, checkoutUserErrors, userErrors } = data.checkoutCreate || {};
  const errors = [...(checkoutUserErrors || []), ...(userErrors || [])];

  if (errors.length) {
    throw new ExtendedError({
      errors,
      code: 'shopify_user_error',
      message: 'User errors occurred during GraphQL execution',
    });
  }

  return checkout || {};
};

/**
 * @param {string} id - Shopify checkout ID
 * @param {object} [options] - Fetcher options
 * @param {object} [options.params] - Query params
 * @returns Promise<object>
 */
export const getCheckout = async (id, options = {}) => {
  const query = `
    query($id: ID!) {
      node(id: $id) {
        ...CheckoutFragment
      }
    }

    ${checkoutFragment}
  `;

  const variables = { id };
  const data = await fetcher({ query, variables, ...options });
  return data.node || {};
};

/**
 * @param {string} id - Shopify cart ID
 * @param {object} [options] - Fetcher options
 * @param {object} [options.params] - Query params
 * @returns Promise<object>
 */
export const getCart = async (id, options = {}) => {
  const query = `
    query($id: ID!) {
      cart(id: $id) {
        ...CartFragment
      }
    }

    ${cartFragment}
  `;

  const variables = { id };
  const data = await fetcher({ query, variables, ...options });
  return data.cart || {};
};

/**
 * @param {object} [input] - Shopify cart create input
 * @param {object} [options] - Fetcher options
 * @param {object} [options.params] - Query params
 * @returns Promise<object>
 */
export const createCart = async (input = {}, options = {}) => {
  const query = `
    mutation ($input: CartInput) {
      cartCreate(input: $input) {
        cart {
          ...CartFragment
        }
        userErrors {
          ...CartUserErrorFragment
        }
      }
    }

    ${cartFragment}
    ${cartUserErrorFragment}
  `;

  const variables = { input };
  const data = await fetcher({ query, variables, ...options });
  const { cart, userErrors } = data.cartCreate || {};

  if (userErrors.length) {
    throw new ExtendedError({
      errors: userErrors,
      code: 'shopify_user_error',
      message: 'User errors occurred during GraphQL execution',
    });
  }

  return cart || {};
};

/**
 * @param {string} [cartId] - Shopify cart ID
 * @param {object[]} [lines] - Merchandise lines
 * @returns Promise<object>
 */
export const addCartLines = async (cartId, lines) => {
  const query = `
    mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
      cartLinesAdd(cartId: $cartId, lines: $lines) {
        cart {
          ...CartFragment
        }
        userErrors {
          ...CartUserErrorFragment
        }
      }
    }

    ${cartFragment}
    ${cartUserErrorFragment}
  `;

  const variables = { cartId, lines };
  const data = await fetcher({ query, variables });
  const { cart, userErrors } = data.cartLinesAdd || {};

  if (userErrors.length) {
    throw new ExtendedError({
      errors: userErrors,
      code: 'shopify_user_error',
      message: 'User errors occurred during GraphQL execution',
    });
  }

  return cart || {};
};

/**
 * @param {string} [cartId] - Shopify cart ID
 * @param {string[]} [lineIds] - Merchandise line IDs
 * @returns Promise<object>
 */
export const removeCartLines = async (cartId, lineIds) => {
  const query = `
    mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
      cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
        cart {
          ...CartFragment
        }
        userErrors {
          ...CartUserErrorFragment
        }
      }
    }

    ${cartFragment}
    ${cartUserErrorFragment}
  `;

  const variables = { cartId, lineIds };
  const data = await fetcher({ query, variables });
  const { cart, userErrors } = data.cartLinesRemove || {};

  if (userErrors.length) {
    throw new ExtendedError({
      errors: userErrors,
      code: 'shopify_user_error',
      message: 'User errors occurred during GraphQL execution',
    });
  }

  return cart || {};
};

/**
 * @param {string} [cartId] - Shopify cart ID
 * @param {object[]} [lines] - Merchandise lines
 * @returns Promise<object>
 */
export const updateCartLines = async (cartId, lines) => {
  const query = `
    mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
      cartLinesUpdate(cartId: $cartId, lines: $lines) {
        cart {
          ...CartFragment
        }
        userErrors {
          ...CartUserErrorFragment
        }
      }
    }

    ${cartFragment}
    ${cartUserErrorFragment}
  `;

  const variables = { cartId, lines };
  const data = await fetcher({ query, variables });
  const { cart, userErrors } = data.cartLinesUpdate || {};

  if (userErrors.length) {
    throw new ExtendedError({
      errors: userErrors,
      code: 'shopify_user_error',
      message: 'User errors occurred during GraphQL execution',
    });
  }

  return cart || {};
};
