import React from 'react';
import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';

import SafeAnchor from '../SafeAnchor';

import renderIcon from '../../utils/render-icon';
import { isExternalUrl } from '../../utils';

const ns = `link`;

const Link = React.forwardRef(
	(
		{
			className,
			disabled,
			variant,
			to,
			href,
			...props
		},
		ref
	) => {
		const rootClassnames = classNames({
			[`${ ns }`]: true,
			[`${ ns }--${ variant }`]: variant,
			[className]: className,
		});

		if (variant === 'cta') {
			// eslint-disable-next-line
			props.children = (
				<div>
					<span>{props.children}</span>
					<span className="arrow">
						{renderIcon('arrow')}
					</span>
				</div>
			);
		}

		if (isExternalUrl(href) || isExternalUrl(to)) {
			return (
				<SafeAnchor
					{...props} // eslint-disable-line
					disabled={disabled}
					href={href || to}
					ref={ref}
					target="_blank"
					className={classNames(rootClassnames)}
				/>
			);
		}

		if (disabled) {
			props.tabIndex = -1; // eslint-disable-line
			props['aria-disabled'] = true; // eslint-disable-line
		}

		const { sameTab, ...rest } = props;

		return (
			<GatsbyLink
				{...rest} // eslint-disable-line
				disabled={disabled}
				to={to || href || '/'}
				ref={ref}
				className={rootClassnames}
			/>
		);
	}
);

Link.defaultProps = {
	disabled: false,
};

export default Link;
