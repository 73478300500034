/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */

import React from 'react';
import fetch from 'isomorphic-fetch';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import camelCase from 'lodash.camelcase';

import {
	STOREFRONT_API_URL,
	STOREFRONT_API_ACCESS_TOKEN
} from './config';

export { default as normalize } from './normalize';
/**
 * Fetch Shopify Storefront API data
 * @param {string} graphql query
 */
export const getShopifyData = query => {
	return fetch(
		STOREFRONT_API_URL,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-Shopify-Storefront-Access-Token': STOREFRONT_API_ACCESS_TOKEN,
			},
			body: JSON.stringify({ query }),
		}
	)
		.then(response => {
			if (response.status !== 200) {
				throw new Error('cannot fetch data');
			}
			return response;
		})
		.then(response => {
			return response.json();
		})
		.then(json => {
			if (json.errors?.length) {
				// eslint-disable-next-line
				console.error(`Storefront API key may not be set in this Product's Sales Channel`, json.errors);
			}
			return json;
		})
		.catch(error => {
			// eslint-disable-next-line
			console.error(error);
			return null;
		});
};

/**
 * Parses text that comes from Shopify's richtext field
 * @param {object} from Shopify's richtext metafield
 * @returns
 */
export const parseRichText = richText => {
	let html = '';

	richText.children.forEach(node => {
		const bold = node.children[0].bold ? '<strong>' : '';
		switch (node.type) {
			case 'heading':
				html += `<h${ node.level }>${ node.children[0].value }</h${ node.level }>`;
				break;
			case 'paragraph':
				html += `<p>${ bold }${ node.children[0].value }</strong></p>`;
				break;
			default:
				break;
		}
	});

	return html;
};

/**
 * Get an array of products given an array of product handles
 * @param {string} product handle
 */
export const getProductsByHandle = async(handlesArr = []) => {
	const promiseArray = [];

	handlesArr.forEach(handle => {
		const productQuery = `
			{
				product: productByHandle(handle: "${ handle }") {
					id
					title
					handle
					tags
					priceRange {
						minVariantPrice {
							amount
							currencyCode
						}
					}
					images(first: 1) {
						edges {
							node {
								altText
								originalSrc
							}
						}
					}
				}
			}
		`;

		promiseArray.push(getShopifyData(productQuery));
	});

	const response = await Promise.all(promiseArray);

	return response
		.filter(({ data }) => {
			return data?.product;
		})
		.map(({ data }) => {
			return {
				product: data.product,
			};
		});
};

/**
 * Get a Collection by its handle
 * @param {string} collection handle
 * @param {number} retrive the first x products
 */
export const getCollectionByHandle = async(handle = '', first = 3) => {
	const collectionQuery = `
		{
			collection: collectionByHandle(handle: "${ handle }") {
				title
				products(first: ${ first }) {
					edges {
						product: node {
							id
							title
							handle
							priceRange {
								minVariantPrice {
									amount
									currencyCode
								}
							}
							images(first: 1) {
								edges {
									node {
										altText
										originalSrc
									}
								}
							}
						}
					}
				}
			}
		}
	`;

	const response = await getShopifyData(collectionQuery);
	return response;
};

/**
 * Determine if url is an external url
 * @param {string} url.
 */
export const isExternalUrl = url => {
	let isExternal = false;

	if (url && (url.includes('//') || url.match(/((^(mailto|tel|sms|mms):)|www.)/) || url.includes('#'))) {
		isExternal = true;
	}

	return isExternal;
};

/**
 * Used to set meta image in SEO component (from seo object or image object)
 * @param {object} image
 * @param {object} seo
 */
export const setMetaImage = (image, seo) => {
	let metaImage = null;
	if (seo?.metaImage) {
		metaImage = seo.metaImage.sourceUrl;
	} else if (image) {
		metaImage = image.sourceUrl;
	}

	return metaImage;
};

/**
 * Return embed url and type given a YT or Vimeo url
 * @param {string} url.
 */
export const getVideoEmbedData = url => {
	if (!url) return false;

	const pattern = /(\/\/.+\/)(.+v=)?([a-zA-Z0-9-]+)($|\?.+)/;
	const matches = url.match(pattern);

	if (!matches) return false;

	const videoId = matches[3]; // Video ID is 3rd capturing group.
	let src;
	let type;

	if (url.indexOf('youtu') !== -1) {
		type = `youtube`;
		src = `https://www.youtube.com/embed/${ videoId }?autoplay=1`;
	} else if (url.indexOf('vimeo') !== -1) {
		type = `vimeo`;
		src = `https://player.vimeo.com/video/${ videoId }`;
	} else {
		return false;
	}

	return {
		type,
		src,
	};
};

/**
 * Return parsed json or false
 * @param {string} JSON.
 */

export const parseJson = json => {
	let output = false;

	if (json) {
		try {
			output = JSON.parse(json);
			return output;
		} catch {
			// eslint-disable-next-line
			console.error('error parsing related products');
		}
	}

	return output;
};

/**
 * Return Shopify metafield given a key
 * @param {array} metafields
 * @param {string} key
 */

export const getMetafield = (metafields = [], key = '') => {
	const output = metafields.find(field => {
		return field.key === key;
	});

	return output;
};

export const getDefaultSchema = () => {
	//	schema
	const structuredData = {
		'@context': 'https://schema.org/',
		'@type': 'Organization',
		name: 'Nordic Naturals',
		url: 'https://www.nordic.com/',
		address: '111 Jennings Drive, Watsonville, California 95076',
		location: '111 Jennings Drive, Watsonville, California 95076',
	};
	return structuredData;
};

/**
 * Formats a currency according to the user's locale
 * @param {string} currency The ISO currency code
 * @param {number} value The amount to format
 * @returns
 */

export const formatPrice = (currency, value) => {
	return Intl.NumberFormat('en-US', {
		currency,
		minimumFractionDigits: 2,
		style: 'currency',
	}).format(value);
};

export const stripURL = str => {
	if (!str) return undefined;
	return str.endsWith('/') ? str.slice(0, -1) : str;
};

export const validateEmail = email => {
	// eslint-disable-next-line
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	// eslint-disable-next-line
	return regexp.test(email);
};

/**
 * Breaks up or 'chunks' array to smaller parts
 * @param {array} array of items
 * @param {number} size the number of itmes per chunk
 * @returns
 */

export const chunkArray = (array, size) => {
	return array.reduce((chunks, item, i) => {
		if (i % size === 0) {
			chunks.push([item]);
		} else {
			chunks[chunks.length - 1].push(item);
		}
		return chunks;
	}, []);
};

/**
 * Renders ifram markup for Youtube or Vimeo given url
 * @param {string} url YT or Vimeo url
 * @returns
 */

export const getVideoIframe = url => {
	const pattern = /(\/\/.+\/)(.+v=)?([a-zA-Z0-9-]+)($|\?.+)/;
	const matches = url.match(pattern);

	if (!matches) return false;

	const videoId = matches[3]; // Video ID is 3rd capturing group.
	let iframeSrc;
	let type;

	if (url.indexOf('youtu') !== -1) {
		type = `youtube`;
		iframeSrc = `https://www.youtube.com/embed/${ videoId }`;
	} else if (url.indexOf('vimeo') !== -1) {
		type = `vimeo`;
		iframeSrc = `https://player.vimeo.com/video/${ videoId }`;
	} else {
		return false;
	}

	return (
		<div className="iframe-container">
			<iframe
				src={iframeSrc}
				title={`${ type }-video`}
				width={640}
				height={360}
				frameBorder="0"
				allowFullScreen
			/>
		</div>
	);
};

export const tryParse = s => {
	try {
		return isNaN(s) ? JSON.parse(s) : s;
	} catch {
		return s;
	}
};

export const tryParseDeep = u => {
	try {
		const o = tryParse(u);
		const ret = Object.entries(o).reduce((acc, cur) => {
			const [k, v] = cur;
			const parsed = typeof v === 'string' ? tryParse(v) : tryParseDeep(v);
			acc[k] = parsed;
			return acc;
		}, o);

		return ret;
	} catch {
		return u;
	}
};

export const isObject = u => {
	return !!u && typeof u === 'object';
};

export const isPlainObject = u => {
	return isObject(u) && !Array.isArray(u);
};

export const objectDifference = (base = {}) => (derived = {}) => {
	const derivedKeys = Object.keys(derived);
	const difference = derivedKeys.reduce((acc, cur) => {
		const baseValue = base[cur];
		const derivedValue = derived[cur];
		const isSame = isEqual(baseValue, derivedValue);

		if (!isSame) {
			acc[cur] = isPlainObject(derivedValue) && isPlainObject(baseValue)
				? objectDifference(baseValue)(derivedValue)
				: derivedValue;
		}

		return acc;
	}, {});

	return difference;
};

export const isObjectDifferent = (initial = {}, updated = {}) => {
	const difference = objectDifference(initial)(updated);
	return !isEmpty(difference);
};

export const camelCaseObjectKeys = o => {
	if (!isObject(o)) {
		return o;
	}

	const model = Array.isArray(o) ? [] : {};
	const ret = Object.entries(o).reduce((acc, cur) => {
		const [key, value] = cur;
		const cKey = camelCase(key);
		const cValue = isObject(value) ? camelCaseObjectKeys(value) : value;
		acc[cKey] = cValue;
		return acc;
	}, model);

	return ret;
};

export const noop = () => () => {
	console.warn('Not implemented');
};

export const get = (input, path, defaultValue) => {
	try {
		let value = input;
		const fields = Array.isArray(path) ? path : path.split('.');

		for (let i = 0; i < fields.length; i++) {
			const field = fields[i];
			value = value[field];
		}

		return value ?? defaultValue;
	} catch {
		return defaultValue;
	}
};

// Only accepts specifiers 's' and 'd'
export const sprintf = (s, ...args) => {
	let pos = 0;
	const l = Array.isArray(args[0]) ? args[0] : args;
	const re = /%s|%d/g;
	const ret = s.replace(re, () => {
		const v = l?.[pos];
		pos += 1;
		return String(v);
	});

	return ret;
};

/**
 * Retrieves the value of the specified metafield key from the provided array.
 * @param {Array<Object>} metafields - An array of metafields objects.
 * @param {string} keyName - The key name to search for.
 * @returns {string} - The value of the specified metafield key, or undefined if not found.
 */
export const getMetafieldValue = (metafields, keyName) => {
  return metafields.find(metafield => metafield.key === keyName)?.value;
};

/**
 * Get an array of products given an array of product ids
 * @param {string} product id
 */
export const getProductsById = async(idsArr = []) => {
	const promiseArray = [];

	idsArr.forEach(id => {
		const productQuery = `
			{
				product(id: "${id}") {
					id
					title
					handle
					tags
					variants(first: 100) {
						edges {
							node {
								id
								sku
								title
							}
						}
					}
					priceRange {
						minVariantPrice {
							amount
							currencyCode
						}
					}
					images(first: 1) {
						edges {
							node {
								altText
								originalSrc
							}
						}
					}
				}
			}
		`;

		promiseArray.push(getShopifyData(productQuery));
	});

	const response = await Promise.all(promiseArray);

	return response
		.filter(({ data }) => {
			return data?.product;
		})
		.map(({ data }) => {
			return {
				product: data.product,
			};
		});
};

// Validates US zipcodes
export const isValidUSZip = s => {
	const regex = /^\d{5}(-\d{4})?$/;
	const isValid = regex.test(s);
	return isValid;
};

export const formatPhoneNumber = (value) => {
	if (!value) return value;

	const phoneNumber = value.replace(/[^\d]/g, '');
	if (phoneNumber.length < 4) return phoneNumber;
	if (phoneNumber.length < 7) {
		return `(${phoneNumber.slice(0, 3)})${phoneNumber.slice(3)}`;
	}
	return `(${phoneNumber.slice(0, 3)})${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const gidUrlToId = gidUrl => {
	if (!gidUrl) return null;
	const match = gidUrl.match(/gid:\/\/shopify\/\w+\/(\d+)/);
	return match ? match[1] : null;
};

export const getElevarDataVariantId = item => {
	const isNumericId = id => /^\d+$/.test(id);
	return gidUrlToId(item?.variants?.[0]?.shopifyId) ||
		gidUrlToId(item?.variants?.edges?.[0]?.node?.shopifyId) ||
		gidUrlToId(item?.variants?.[0]?.id) ||
		gidUrlToId(item?.variant?.id) ||
		(item?.id && item?.id.includes("gid://shopify/ProductVariant/") ? gidUrlToId(item?.id) : '') ||
		(item?.id && !item?.id.includes("gid://shopify/Product/") && isNumericId(item?.id) ? item?.id : '') ||
		gidUrlToId(item?.variants?.edges?.[0]?.node?.id) ||
		'';
};

export const getDiscountAmount = (item) => {
	try {
		if (!item?.customAttributes?.length) return null;

		const sellingPlanGroupAttribute = item.customAttributes.find(attr => attr.key === '_sellingPlanGroup');
		if (!sellingPlanGroupAttribute) return null;

		const sellingPlanGroup = JSON.parse(sellingPlanGroupAttribute.value);
		return sellingPlanGroup?.include?.product?.discount_amount || null;
	} catch (error) {
		console.error('Error parsing selling plan group:', error);
		return null;
	}
};

export const calculateDiscountedPrice = (discountAmount, price) => {
  const priceInCents = Math.round(price * 100);
  const discountPercentage = discountAmount / 100;
  const discountedPriceInCents = Math.round(priceInCents * (1 - discountPercentage));
  const roundedPrice = discountedPriceInCents / 100;

  return roundedPrice.toFixed(2).toString();
};

export const getElevarDataPrice = item => {
  try {
    const price = item?.priceRangeV2?.minVariantPrice?.amount?.toString() ||
      item?.variant?.price?.amount?.toString() ||
      item?.price?.toString() ||
      item?.priceRange?.minVariantPrice?.amount?.toString();

    if (!price) return '0.00';

    const discountAmount = getDiscountAmount(item);
    if (discountAmount === null) return price;

    return calculateDiscountedPrice(discountAmount, parseFloat(price));
  } catch (error) {
    console.error('Error getting Elevar data price:', error);
    return '';
  }
};

export const getElevarDataItemURL = item => {
	let url = '';
	if (item?.slug) {
		url = item.slug;
	} else if (item?.handle) {
		url = `/products/nordic-naturals/${item.handle}`;
	} else if (item?.variant?.product?.handle) {
		url = `/products/nordic-naturals/${item.variant.product.handle}`;
	} else if (item?.url) {
		url = item.url.replace(/https?:\/\/[^/]+/, '');
	}

	const variantId = getElevarDataVariantId(item);
	if (url && variantId) {
		if (!url.endsWith('/')) {
			url += '/';
		}
		url += `?variant=${variantId}`;
	}

	return url;
};

export const getElevarListValue = ({ item, location }) => {
	let listValue;

	if (item.isQuickshop) {
		listValue = location?.pathname || '';
	} else {
		listValue = location?.state?.prevPath || location?.pathname || '';
	}

	if (listValue.includes('search')) {
		return 'search results';
	}

	return listValue;
};

export const getKlevuVariantName = item => {
	const variantNameValues = [];
	if (item?.format) {
		variantNameValues.push(item.format);
	}
	if (item?.size___flavor) {
		variantNameValues.push(item.size___flavor);
	}
	const variantName = variantNameValues.join(' / ');
	return variantName === '' ? null : variantName;
};

export const convertToElevarDataItem = ({ item, index, location, quantity }) => {
	const obj = {
		id:
			item?.variants?.[0]?.sku ||
			item?.variants?.edges?.[0]?.node?.sku ||
			item?.variant?.sku ||
			item?.sku ||
			'',
		name:
			item?.title ||
			item?.name ||
			item?.variant?.product?.title ||
			'',
		brand: 'Nordic Naturals',
		category: 'Nordic Naturals',
		variant:
			item?.variants?.[0]?.title ||
			item?.variants?.edges?.[0]?.node?.title ||
			item?.variant?.title ||
			getKlevuVariantName(item) ||
			item?.variants?.[0]?.title ||
			'',
		price: getElevarDataPrice(item),
		list: getElevarListValue({ item, location }),
		product_id:
			gidUrlToId(item?.shopifyId) ||
			gidUrlToId(item?.variant?.product?.id) ||
			item?.itemGroupId ||
			gidUrlToId(item?.id) ||
			'',
		variant_id: getElevarDataVariantId(item),
		compare_at_price:
			item?.variants?.[0]?.compareAtPrice?.toString() ||
			item?.variants?.edges?.[0]?.node?.compareAtPrice?.amount?.toString() ||
			item?.variant?.compareAtPrice?.amount?.toString() ||
			(item?.startPrice < item?.price ? item?.startPrice?.toString() : null) ||
			'0.0',
		image:
			item?.images?.[0]?.image?.src ||
			item?.images?.edges?.[0]?.node?.src ||
			item?.images?.edges?.[0]?.node?.originalSrc ||
			item?.variant?.image?.url ||
			item?.variant?.image?.src ||
			item?.image ||
			'',
		url: getElevarDataItemURL(item),
	};

	if (index != null) {
		obj.position = index + 1;
	}

	if (quantity != null) {
		obj.quantity = quantity?.toString() || '';
	} else if (item?.quantity) {
		obj.quantity = item.quantity.toString() || '';
	}

	return obj;
};
