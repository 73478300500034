/* eslint-disable max-len */
import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';
import classNames from 'classnames';
import { KlevuFetch, search, suggestions, sendSearchEvent, KlevuEvents } from '@klevu/core';
import { navigate } from 'gatsby';
import uniqueId from 'uniqid';
import parse from 'html-react-parser';
import { SearchProvider } from '../../context/search-provider';
import { AuthContext } from '../../context/auth-context';
import renderIcon from '../../utils/render-icon';
import Link from '../Link';

const ns = `quicksearch`;

const QuickSearch = ({ handleSearchOpen, handleMobileNavClose, location }) => {
	const rootClassnames = classNames({
		[`${ns}`]: true,
	});

	const { state: authState } = useContext(AuthContext);
	const { auth = {} } = authState;

	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [sugs, setSuggestions] = useState([]);
	const searchInput = useRef(null);
	const quickSearch = useRef(null);

	const debounce = (callback, wait) => {
		let timeoutId = null;
		return (...args) => {
			window.clearTimeout(timeoutId);
			timeoutId = window.setTimeout(() => {
				callback(null, ...args);
			}, wait);
		};
	};

	// const siteHeader = document.querySelector('.site-header');

	const handleSearchButton = () => {
		searchInput.current.focus();
		setIsSearchOpen(!isSearchOpen);
		handleSearchOpen();
	};

	const handleFocus = () => {
		setIsSearchOpen(true);
	};

	const closeSearch = useCallback(() => {
		setSearchValue('');
		setSearchResults([]);
		setSuggestions([]);
		setIsSearchOpen(false);
		handleSearchOpen();
	}, [handleSearchOpen]);

	const handleClose = useCallback(() => {
		closeSearch();
		handleMobileNavClose();
	}, [closeSearch, handleMobileNavClose]);

	const handleSearchClose = useCallback(() => {
		closeSearch();
	}, [closeSearch]);

	useEffect(() => {
		const checkIfClickedOutside = e => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (isSearchOpen && quickSearch?.current && !quickSearch?.current.contains(e.target)) {
				handleClose();
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [handleClose, isSearchOpen]);

	const handleSearchSubmit = e => {
		e.preventDefault();
		handleClose();
		// handleMobileNavClose();
		navigate(`/search?q=${searchValue}`, {
			state: {
				prevPath: location?.pathname?.includes('search') ? 'search results' : location?.pathname || '',
			}
		});
	};

	const fetch = debounce(async() => {
		const searchTerm = searchInput.current.value;
		if (searchTerm.length < 2) {
			return;
		}

		const result = await KlevuFetch(
			search(searchTerm, {
				id: 'search',
				limit: 6,
				fields: ['name', 'id', 'url', 'image', 'price', 'startPrice'],
			}),
			suggestions(searchTerm),
			[sendSearchEvent()]
		);

		setSearchResults(result.queriesById('search')?.records);

		setSuggestions(
			result.suggestionsById('suggestions')?.suggestions.map(i => {
				return i.suggest;
			}) ?? []
		);
	}, 300);

	return (
		<SearchProvider>
			<div ref={quickSearch} className={rootClassnames}>
				<div className="site-header__search">
					<button
						type="button"
						className="site-header__icon site-header__icon-search"
						onClick={handleSearchButton}
						aria-label="search"
					>
						{renderIcon('search')}
					</button>
					<form action="" onSubmit={handleSearchSubmit}>
						<input
							onFocus={handleFocus}
							// onBlur={handleBlur}
							onChange={e => {
								setSearchValue(e.target.value);
							}}
							onKeyUp={() => {
								fetch();
							}}
							type="text"
							placeholder="Search"
							name="search"
							ref={searchInput}
							value={searchValue}
							autoComplete="off"
						/>
					</form>
					<button
						type="button"
						className="site-header__icon site-header__icon-close"
						onClick={handleSearchClose}
					>
						{renderIcon('xl')}
					</button>
				</div>
				{searchResults && searchResults.length > 0 && (
					<div className="results-container">
						<div className="results">
							{sugs.length > 0 ? (
								<div className="suggestions-list">
									<ul>
										{sugs.map(s => {
											const suggestionStripped = s.replace(/<[^>]*>?/gm, '');
											const encodedSearch = encodeURIComponent(suggestionStripped);
											return (
												<li key={uniqueId()}>
													<Link to={`/search?q=${encodedSearch}`} onClick={handleClose}>
														{parse(s)}
													</Link>
												</li>
											);
										})}
									</ul>
								</div>
							) : (
								<span className="visually-hidden">No suggestions found.</span>
							)}

							<div className={`search-results-list ${sugs.length === 0 ? 'no-sugs' : ''}`}>
								<h4>Products</h4>
								<Link className="view-all" to={`/search?q=${searchValue}`} onClick={handleClose}>
									View All
								</Link>
								<ul>
									{searchResults.map(product => {
										return (
											<li key={uniqueId()}>
												<Link
													// pro products urls are '/products/nordic-naturals/...' but klevu returns '/products/...'
													to={product.url.replace(/https?:\/\/[^/]+/, '').replace('/products', '/products/nordic-naturals')}
													onClick={() => {
														handleClose();
														KlevuEvents.searchProductClick(product, searchValue, product.id);
													}}
												>
													<img src={product.image} alt={product.name} />
													<div className="product-info">
														<h5>{product.name}</h5>
														{auth.isAuthorized && (
															<p>
																{`From $${product.startPrice ? product.startPrice : product.price}`}
															</p>
														)}
													</div>
												</Link>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					</div>
				)}
			</div>
		</SearchProvider>
	);
};

export default QuickSearch;
