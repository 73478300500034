/* eslint-disable prefer-template */

import auth0 from 'auth0-js';
import { Auth0Error, ExtendedError } from '../error';
import { get, camelCaseObjectKeys, isPlainObject } from '..';
import {
	AUTH0_DATABASE,
	AUTH0_DOMAIN,
	AUTH0_SCOPE,
	AUTH0_RESPONSE_TYPE,
	AUTH0_CLIENT_ID,
	AUTH0_MANAGEMENT_IDENTIFIER,
	AUTH0_MANAGEMENT_SCOPE,
	AUTH0_REDIRECT_URI,
	POST_EMAIL_VERIFICATION_URL,
} from '../config';

function promisify(fn) {
	return (...args) => {
		return new Promise((resolve, reject) => {
			const callback = (error, result) => {
				if (error) {
					const auth0Error = new Auth0Error(error);
					reject(auth0Error);
				} else {
					resolve(result);
				}
			};

			args.push(callback);
			fn.call(this, ...args);
		});
	};
}

function withExpiryDt(data) {
	if (!isPlainObject(data)) {
		return data;
	}

	// "expiresIn" set in seconds
	const { expiresIn = 0 } = data;
	const currentDt = Math.round(Date.now() / 1000);
	const expiresAt = currentDt + expiresIn;

	return { ...data, expiresAt };
}

const webAuthConfig = {
	audience: AUTH0_MANAGEMENT_IDENTIFIER,
	clientID: AUTH0_CLIENT_ID,
	domain: AUTH0_DOMAIN,
	responseType: AUTH0_RESPONSE_TYPE,
	scope: `${AUTH0_SCOPE} ${AUTH0_MANAGEMENT_SCOPE}`,
};

export const webAuth = new auth0.WebAuth(webAuthConfig);

/**
 * @param {object} user
 * @param {string} user.email
 * @param {string} user.password
 * @param {string} [user.username]
 * @param {string} [user.given_name]
 * @param {string} [user.family_name]
 * @param {string} [user.name]
 * @param {string} [user.nickname]
 * @param {object} [user.user_metadata]
 * @returns Promise<object>
 */
export const signupAndAuthorize = async user => {
  const options = {
    ...user,
		username: user.username || user.email,
    connection: AUTH0_DATABASE,
  };
	const signupAndAuthorizeAsync = promisify.call(webAuth, webAuth.signupAndAuthorize);
	const data = await signupAndAuthorizeAsync(options);

	return withExpiryDt(data);
};

/**
 * @param {object} user
 * @param {string} user.email
 * @param {string} user.password
 * @param {string} [user.username]
 * @returns Promise<object>
 */
export const login = async user => {
	const { client } = webAuth;
  const options = {
    ...user,
		username: user.username || user.email,
    realm: AUTH0_DATABASE,
  };
	const loginAsync = promisify.call(client, client.login);
	const data = await loginAsync(options);

	return withExpiryDt(data);
};

/**
 * @param {object} user
 * @param {string} user.email
 * @param {string} user.password
 * @param {string} [user.username]
 * @param {string} [user.given_name]
 * @param {string} [user.family_name]
 * @param {string} [user.name]
 * @param {string} [user.nickname]
 * @param {object} [user.user_metadata]
 * @returns Promise<object>
 */
export const loginOrRegister = user => {
	return login(user).catch(signupAndAuthorize.bind(null, user));
};

/**
 * @param {string} accessToken
 * @returns Promise<object>
 */
export const userInfo = accessToken => {
	const { client } = webAuth;
	const userInfoAsync = promisify.call(client, client.userInfo);
	return userInfoAsync(accessToken);
};

/**
 * @param {string} email
 * @returns Promise<object>
 */
export const changePassword = email => {
	const options = {
		email,
		connection: AUTH0_DATABASE,
	};
	const changePasswordAsync = promisify.call(webAuth, webAuth.changePassword);
	return changePasswordAsync(options);
};

/**
 * Updates user metadata using Management API
 * @param {string} accessToken
 * @param {string} userId
 * @param {object} userMetadata
 * @returns Promise<object>
 */
export const patchUserMetadata = (accessToken, userId, userMetadata) => {
	const management = new auth0.Management({
		domain: AUTH0_DOMAIN,
		token: accessToken,
	});

	const patchUserMetadataAsync = promisify.call(management, management.patchUserMetadata);
	return patchUserMetadataAsync(userId, userMetadata);
};

/**
 * @param {string} code
 * @returns Promise<object>
 */
export const codeExchange = async code => {
	const endpoint = '/api/oauth_token';
	const body = {
		code,
		grantType: 'authorization_code',
		redirectURI: AUTH0_REDIRECT_URI,
	};

	const options = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	};

	const response = await fetch(endpoint, options);
	const { data, error } = await response.json();

	if (error || !response.ok) {
		throw new ExtendedError({
			response,
			code: get(error, 'code') || 'gatsby_auth0_http_error',
			name: get(error, 'name') || 'auth0_grant_error',
			message: get(error, 'message') || 'Failed to fetch Nordic Auth0 API',
			status: response.status,
			statusText: response.statusText,
		});
	}

	return withExpiryDt(camelCaseObjectKeys(data)) || {};
};

/**
 * @param {string} refreshToken
 * @returns Promise<object>
 */
export const tokenExchange = async (refreshToken) => {
	const endpoint = '/api/oauth_token';
	const body = {
		refreshToken,
		grantType: 'refresh_token',
	};

	const options = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	};

	const response = await fetch(endpoint, options);
	const { data, error } = await response.json();

	if (error || !response.ok) {
		throw new ExtendedError({
			response,
			code: get(error, 'code') || 'gatsby_auth0_http_error',
			name: get(error, 'name') || 'auth0_grant_error',
			message: get(error, 'message') || 'Failed to fetch Nordic Auth0 API',
			status: response.status,
			statusText: response.statusText,
		});
	}

	return withExpiryDt(camelCaseObjectKeys(data)) || {};
};

/**
 * @param {object} token
 * @param {string} token.refreshToken
 * @param {string} [token.expiresAt]
 * @param {boolean} [shouldForce]
 * @returns Promise<object>
 */
export const refreshToken = (token, shouldForce = false) => {
	const { expiresAt = 0 } = token;

	// "expiresAt" set in seconds
	const currentDt = Math.round(Date.now() / 1000);

	// Auth0 tokens expire after 24 hours (in seconds)
	const twelveHours = 60 * 60 * 12;
	const remainingDt = expiresAt - currentDt;

	if (remainingDt > twelveHours && !shouldForce) {
		return Promise.resolve(token);
	}

	return tokenExchange(token.refreshToken);
};

/**
 * @param {string} userId
 * @returns Promise<object>
 */
export const sendVerificationEmail = async userId => {
	const options = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ user_id: userId }),
	};

	const response = await fetch(POST_EMAIL_VERIFICATION_URL, options);
	const json = await response.json();
	const { error } = json;

	if (error || !response.ok) {
		throw new ExtendedError({
			response,
			code: get(error, 'errorCode') || 'auth0_error',
			name: get(error, 'error') || 'auth0_post_email_verification_error',
			message: get(error, 'message') || 'Post email verification failed',
			status: response.status,
			statusText: response.statusText,
		});
	}

	return camelCaseObjectKeys(json) || {};
};

export const updateShopifyCustomerId = async (auth0UserId, shopifyCustomerId, token) => {
	const body = JSON.stringify({
		user_metadata: {
			shopifyCustomerId
		}
	});

	const response = await fetch(`https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/users/${auth0UserId}`, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		},
		body
	});

	if (!response.ok) {
		throw new Error(`Error updating user: ${response.statusText}`);
	}

	return response.json();
};

export const getUser = async (auth0UserId, token) => {
	if (!auth0UserId) {
		throw new Error(`Error getting user data: no user id`);
	}
	const response = await fetch(`https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/users/${auth0UserId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		},
	});

	if (!response.ok) {
		throw new Error(`Error updating user: ${response.statusText}`);
	}

	return response.json();
};
