/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */
import slugify from '@sindresorhus/slugify';

import {
	stateOptions,
	emptyAddress,
	practiceTypeOptions,
	specialtyOptions
} from '../data';

function parseNull(s) {
	return s === 'null' ? null : s;
}

// https://gist.github.com/kevinweber/1249fde7b3d26fe73e1be0d52d3c023a
export const phone = string => {
	if (!string) {
		return '';
	}

	let newString = string.match(/[0-9]{0,14}/g);

	if (newString === null) {
		return '';
	}

	// Join parts returned from RegEx match
	newString = newString.join('');

	if (newString.length === 10) {
		newString = '1' + newString;
	}

	// Start number with "+"
	newString = '+' + newString;

	// Limit length to 15 characters
	newString = newString.substring(0, 15);

	return newString;
};

export const renderStartEndDates = (startDate, endDate) => {
	if (!startDate && !endDate) return null;
	if (!startDate && endDate) return endDate;
	if (startDate && !endDate) return startDate;

	const startDateArr = startDate.split(' ');
	const endDateArr = endDate.split(' ');

	const startMonth = startDateArr[0];
	const startDay = startDateArr[1].replace(',', '');
	const startYear = startDateArr[2];

	const endMonth = endDateArr[0];
	const endDay = endDateArr[1].replace(',', '');
	const endYear = endDateArr[2];

	if (startDate === endDate) {
		return startDate;
	}

	if (startMonth === endMonth && startYear === endYear) {
		return `${ startMonth } ${ startDay }-${ endDay }, ${ endYear }`;
	}

	return `${ startDate } - ${ endDate }`;
};

// Shopify Address -> Local
export const fromShopifyAddress = (address = {}, decorations = {}) => {
	address = address || {};

	const province = address.province || '';
	const provinceCode = address.provinceCode || '';
	const state =
		stateOptions.find(opt => {
			const { value, label } = opt;
			return (
				value.toUpperCase() === provinceCode.toUpperCase() ||
				label.toUpperCase() === province.toUpperCase()
			);
		}) || emptyAddress.state;

	const data = {
		state,
		shopifyId: address.id,
		address1: address.address1 || '',
		address2: address.address2 || '',
		company: address.company || address.businessName || '',
		city: address.city || '',
		firstName: address.firstName || '',
		lastName: address.lastName || '',
		phone: address.phone || '',
		zip: address.zip || '',
		...decorations,
	};

	return Object.entries(data).reduce((acc, cur) => {
		const [k, v] = cur;
		acc[k] = parseNull(v);
		return acc;
	}, {});
};

// Local Address -> Shopify
export const toShopifyAddress = (address = {}) => {
	address = address || {};

	const state = address.state || {};

	const data = {
		address1: address.address1,
		address2: address.address2,
		city: address.city,
		company: address.company || address.businessName,
		country: address.country || 'United States',
		firstName: address.firstName,
		lastName: address.lastName,
		phone: address.phone,
		province: state.value,
		zip: address.zip,
	};

	return Object.entries(data).reduce((acc, cur) => {
		const [k, v] = cur;
		acc[k] = parseNull(v);
		return acc;
	}, {});
};

// Salesforce -> Local Address
export const fromSalesforceAddress = (address = {}, decorations = {}) => {
	address = address || {};

	const province = address.province || '';
	const normalizedProvince = province.toUpperCase();
	const state =
		stateOptions.find(option => {
			const { label, value } = option;
			return (
				value.toUpperCase() === normalizedProvince ||
				label.toUpperCase() === normalizedProvince
			);
		}) || emptyAddress.state;

	return {
		state,
		firstName: address.firstName || '',
		lastName: address.lastName || '',
		company: address.name || '',
		address1: address.address1 || '',
		address2: address.address2 || '',
		city: address.city || '',
		zip: String(address.zip || ''),
		phone: String(address.phone || ''),
		secondaryPhone: String(address.phone_secondary || ''),
		website: address.website || '',
		...decorations,
	};
};

// Local Address -> Salesforce
const toSalesforceAddress = (address = {}) => {
	address = address || {};

	const { company, firstName, lastName } = address;
  const state = address.state || {};
	const name = company || `${firstName} ${lastName}`;

	return {
		name,
		address1: address.address1,
		address2: address.address2,
		city: address.city,
		province: state.value,
		zip: address.zip,
		country: address.country || 'United States',
		phone: address.phone,
		phone_secondary: address.secondaryPhone,
	};
};

// Auth0 User Info -> Local
export const fromAuth0Profile = (profile = {}, decorations = {}) => {
	profile = profile || {};

	return {
		email: profile.email || '',
		emailVerified: profile.email_verified || false,
		firstName: profile.given_name || '',
		fullName: profile.name || '',
		lastName: profile.family_name || '',
		metadata: profile.metadata || {},
		nickname: profile.nickname || '',
		sub: profile.sub || profile.user_id || '',
		shopify: {
			multipassToken: profile['shopify/multipassToken'] || '',
			customer: profile['shopify/customer'] || {},
		},
		userId: profile.sub || profile.user_id || '',
		...decorations,
	};
};

// Local User -> Auth0
export const toAuth0User = (user = {}, metadata = {}) => {
	const { firstName = '', lastName = '', email = '' } = user;
	const name = `${ firstName } ${ lastName }`.trim();

	const userMetadata = Object.entries(metadata).reduce((acc, cur) => {
		const [key, value] = cur;
		acc[key] = String(value);
		return acc;
	}, {});

	return {
		name,
		email: email.toLowerCase(),
		family_name: lastName,
		given_name: firstName,
		nickname: user.nickname || firstName,
		password: user.password,
		username: user.username,
		user_metadata: userMetadata,
	};
};

export const fromSalesforceUser = (practitioner = {}, decorations = {}) => {
	const practiceType = practitioner.practiceType || '';
	const specialty = practitioner.specialty || '';
	const normalizedPracticeType = practiceType.toUpperCase();
	const normalizedSpecialty = specialty.toUpperCase();
	const localPracticeType =
		practiceTypeOptions.find(p => {
			const { value, label } = p;
			return (
				value.toUpperCase() === normalizedPracticeType ||
				label.toUpperCase() === normalizedPracticeType
			);
		}) || '';

	const localSpecialty =
		specialtyOptions.find(s => {
			const { value, label } = s;
			return (
				value.toUpperCase() === normalizedSpecialty ||
				label.toUpperCase() === normalizedSpecialty
			);
		}) || '';

	return {
		firstName: practitioner.firstName,
		lastName: practitioner.lastName,
		email: practitioner.email,
		practiceType: localPracticeType,
		specialty: localSpecialty,
		...decorations,
	};
};

const fromCMSEbookArticle = (ebook = {}) => {
	return {
		description: ebook.description,
		headline: ebook.readTime ? `${ ebook.readTime } Min Read` : '',
		cta: {
			label: 'View',
			action: 'VIEW_FILE',
		},
		tag: `<span class="tag ebook">eBOOK</span>`,
		thumbnail: {
			image: ebook.thumbnail,
			type: ebook.thumbnail?.gatsbyImage ? 'GATSBY_IMAGE' : 'URL_IMAGE',
		},
		title: ebook.title,
		variant: 'ebook',
		file: ebook.file,
	};
};

const fromCMSArticleArticle = (article = {}) => {
	return {
		description: article.description,
		headline: `${ article.readTime ? `${ article.readTime } Min Read` : '' }`,
		cta: {
			label: 'View',
			action: 'VIEW_FILE',
		},
		tag: `<span class="tag">Article</span>`,
		thumbnail: {
			image: article.thumbnail,
			type: article.thumbnail?.gatsbyImage ? 'GATSBY_IMAGE' : 'URL_IMAGE',
		},
		title: article.title,
		variant: 'article',
		file: article.file,
	};
};

const fromCMSEventArticle = (event = {}) => {
	const title = event.liveEventBlock?.title || '';
	const link = event.liveEventBlock?.eventLink || '';
	const address = event.liveEventBlock?.eventAddress || '';
	const image = event.liveEventBlock?.image;

	const startDate = new Date(event.liveEventBlock?.eventDate || '').toLocaleDateString('en-US', {
		month: 'short',
		day: 'numeric',
		year: 'numeric'
	}).toUpperCase();

	const endDate = new Date(event.liveEventBlock?.eventEndDate || '').toLocaleDateString('en-US', {
		month: 'short',
		day: 'numeric',
		year: 'numeric'
	}).toUpperCase();

	const isUpcoming = new Date(startDate) > new Date();

	return {
		description: address,
		headline: renderStartEndDates(startDate, endDate),
		cta: {
			label: 'Learn More',
			url: link,
			action: 'VIEW_LINK',
			icon: 'external-link',
		},
		tag: `<span class="tag">Event${ isUpcoming ? ' • Upcoming' : '' }</span>`,
		thumbnail: {
			image,
			type: image?.gatsbyImage ? 'GATSBY_IMAGE' : 'URL_IMAGE',
		},
		title,
		variant: 'event',
	};
};

const fromCMSVideoArticle = (video = {}) => {
	const title = video.title || '';
	const vimeoLink = video.youtubevimeoLink || '';
	const pattern = /(\/\/.+\/)(.+v=)?([a-zA-Z0-9-]+)($|\?.+)/;
	const matches = vimeoLink?.match(pattern);
	const videoId = matches?.[3];
	const videoPoster = `https://player.vimeo.com/video/${ videoId }?controls=0&byline=0&title=0`;
	const videoSource = `https://player.vimeo.com/video/${ videoId }`;

	if (!videoId) return null;

	return {
		description: null,
		headline: null,
		cta: {
			label: 'Play Video',
			action: 'PLAY_VIDEO',
		},
		tag: '<span class="tag">VIDEO</span>',
		videoLink: {
			id: videoId,
			poster: videoPoster,
			source: videoSource,
		},
		thumbnail: {
			image: null,
			type: 'VIDEO',
		},
		title,
		variant: 'video',
	};
};

const fromOn24WebinarArticle = (webinar = {}) => {
	const [firstSpeaker] = webinar.speakers || [];
	const firstSpeakerName = firstSpeaker.name || '';
	const firstSpeakerTitle = firstSpeaker.title || '';
	const eventDurationHours = Math.floor(webinar.eventduration / 60);
	const eventDurationMinutes = webinar.eventduration % 60;
	const isUpcoming = new Date(webinar.livestart) > new Date();
	const image = {
		altText: webinar.description,
		url: webinar.thumbnail,
	};

	const eventDate = new Date(webinar.livestart).toLocaleDateString('en-US', {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	});

	const eventTime = new Date(webinar.livestart).toLocaleTimeString('en-US', {
		hour: 'numeric',
		minute: '2-digit',
		timeZoneName: 'short',
	});

	return {
		description: `Host: ${ firstSpeakerName }${ firstSpeakerTitle ? `, ${ firstSpeakerTitle }` : '' }`,
		headline: isUpcoming
			? `${ eventDate } • ${ eventTime }`
			: `${ eventDurationHours ? `${ eventDurationHours } Hr` : '' }${ eventDurationMinutes ? ` ${ eventDurationMinutes } Min` : '' }`,
		cta: {
			label: 'Register',
			url: `/webinars/${ webinar.eventid }`,
			action: 'VIEW_LINK',
		},
		tag: `<span class="tag">Webinar${ isUpcoming ? ' • Upcoming' : '' }</span>`,
		thumbnail: {
			image,
			type: 'URL_IMAGE',
		},
		title: webinar.description,
		variant: 'webinar',
	};
};

const fromOn24WebinarDetailsArticle = (webinar = {}) => {
	const promotionalsummary = (webinar.promotionalsummary || '').replace(/<[^>]*>/g, '');
	const eventDurationHours = Math.floor(webinar.eventduration / 60);
	const eventDurationMinutes = webinar.eventduration % 60;
	const isUpcoming = new Date(webinar.livestart) > new Date();

	const eventDate = new Date(webinar.livestart).toLocaleDateString('en-US', {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	});

	const eventTime = new Date(webinar.livestart).toLocaleTimeString('en-US', {
		hour: 'numeric',
		minute: '2-digit',
		timeZoneName: 'short',
	});

	return {
		description: promotionalsummary,
		headline: isUpcoming
			? `${ eventDate } • ${ eventTime }`
			: `${ eventDurationHours ? `${ eventDurationHours } Hr` : '' }${ eventDurationMinutes ? ` ${ eventDurationMinutes } Min` : '' }`,
		tag: `<span class="tag">Webinar${ isUpcoming ? ' • Upcoming' : '' }</span>`,
		thumbnail: {
			altText: webinar.description,
			url: webinar.thumbnail,
		},
		title: webinar.description,
		variant: 'webinar',
	};
};

const normalize = {
	phone,
	address: {
		fromShopify: fromShopifyAddress,
		toShopify: toShopifyAddress,
		fromSalesforce: fromSalesforceAddress,
		toSalesforce: toSalesforceAddress,
	},
	article: {
		fromCMSEvent: fromCMSEventArticle,
		fromCMSVideo: fromCMSVideoArticle,
		fromCMSEbook: fromCMSEbookArticle,
		fromCMSArticle: fromCMSArticleArticle,
		fromOn24Webinar: fromOn24WebinarArticle,
		fromOn24WebinarDetails: fromOn24WebinarDetailsArticle,
	},
	profile: {
		fromAuth0: fromAuth0Profile,
	},
	user: {
		fromSalesforce: fromSalesforceUser,
		toAuth0: toAuth0User,
	},
};

export default normalize;
